
enum FAQCategoryKey {
    WhatIsNeotrust = 'WhatIsNeotrust',
    WhoIsNeotrust = 'WhoIsNeotrust',
    HowToTimestamp = 'HowToTimestamp',
    HowToVerify = 'HowToVerify',
    AccountFAQs = 'AccountFAQs',
    PlansAndPricing = 'PlansAndPricing',
}

interface FAQItemProps {
    question: string;
    answer: string;
}
interface FAQsProps {
    key: FAQCategoryKey;
    title: string;
    items: FAQItemProps[];
    altTitle?: string;
    description: string;
}


const FAQs: FAQsProps[] = [
    {
        key: FAQCategoryKey.WhatIsNeotrust,
        title: 'What is Neotrust?',
        description: 'Questions about what Neotrust is and how it works?',
        items: [
            {
                question: 'What is Neotrust?',
                answer: 'Neotrust is a blockchain-based digital trust platform that protects and verifies the authenticity, ownership, and integrity of the data of electronic files and assets. By timestamping and storing files in a secure, decentralized network, Neotrust provides a tamper-proof solution for digital asset management. Turn your files into trusted assets with Neotrust.'
            },
            {
                question: 'How does Neotrust differ from traditional file security?',
                answer: 'Unlike centralized security systems that can be vulnerable to attacks, Neotrust uses blockchain technology, ensuring that once a file is timestamped, it becomes immutable and verifiable without revealing sensitive information. Your information is encrypted and never viewable across decentralized servers. We adhere to most data privacy and compliance laws. To learn more, take a look at how we add trust.'
            },
            {
                question: 'Why would I need to use Neotrust?',
                answer: 'Neotrust is ideal for anyone who needs to secure, verify, and establish ownership over digital files, including professionals in legal, creative, academic, healthcare, and business sectors, as well as anyone concerned with data integrity and intellectual property protection.'
            },
            {
                question: 'Is Neotrust compliant with legal regulations?',
                answer: 'Neotrust is built to offer interoperability and meets regulatory and data compliance standards. We are HIPAA, PHIPA, PIPEDA, and GDPR certified. However, it is advisable to consult with legal experts in your jurisdiction and industry.'
            }
        ]
    },
    {
        key: FAQCategoryKey.WhoIsNeotrust,
        title: 'Who is Neotrust?',
        description: 'Questions about who we are and our journey towards digital trust?',
        items: [
            {
                question: 'What is Neotrust’s mission?',
                answer: 'Neotrust aims to make digital trust accessible to everyone by providing a secure platform for verifying the authenticity, ownership, and integrity of digital files. Using blockchain technology, Neotrust safeguards digital assets of any file format and any size for individuals, organizations, and industries.'
            },
            {
                question: 'Who founded Neotrust?',
                answer: 'Neotrust was founded by Caesar Medel (CEO) and Corey Lanzing (COO), who then partnered with Steve Burg (CTO), co-creator of the ZIP file format. Our team brings decades of expertise in building technology solutions that shape industries with a vision to revolutionize digital trust through a patented blockchain-based digital trust solution. To learn more about us, please visit the Company page.'
            },
            {
                question: 'What does Neotrust offer that’s unique?',
                answer: 'Neotrust provides a decentralized, tamper-proof system for data verification and ownership tracking of digital assets, establishing a trusted chain of custody for files of any format and size. This blockchain-based platform ensures data security and compliance beyond traditional methods, making it a new standard in digital trust.'
            }
        ]
    },
    {
        key: FAQCategoryKey.HowToTimestamp,
        title: 'How to Timestamp',
        description: 'Questions about how to timestamp your files?',
        items: [
            {
                question: 'What is a timestamp, and why is it important?',
                answer: 'A timestamp records the exact date and time a file was uploaded, securing proof of its existence and ownership at that moment. This is crucial for establishing authenticity and ownership in legal and professional contexts.'
            },
            {
                question: 'How do I timestamp a file in Neotrust?',
                answer: 'Once logged into your account, go to the "Timestamp File" section on your dashboard, upload the file, and Neotrust will automatically hash and timestamp it. You’ll receive a confirmation email with your file’s certification.'
            },
            {
                question: 'Can I timestamp multiple files at once?',
                answer: 'Currently, each file must be uploaded and timestamped individually, though our platform supports various file types and sizes. To learn more, see how we add trust.'
            }
        ]
    },
    {
        key: FAQCategoryKey.HowToVerify,
        title: 'How to Verify',
        description: 'Questions about how to verify and authenticate your files?',
        items: [
            {
                question: 'What is the verification certificate?',
                answer: 'Neotrust provides a verification certificate after each file is timestamped. This certificate includes key details like the timestamp, file hash, and block number, which serve as proof of the file’s authenticity and ownership. The certificate is what will allow proving data integrity and chain of custody for your digital assets.'
            },
            {
                question: 'How do I verify a file’s authenticity with Neotrust?',
                answer: 'In your Neotrust account, navigate to the file’s certificate, where you can view the file’s original timestamp, block number, and other details. This information confirms that the file has not been altered since it was timestamped.'
            },
            {
                question: 'Can others verify my file without accessing sensitive details?',
                answer: 'Yes, Neotrust’s certificates allow permitted parties to confirm a file’s authenticity and timestamp without revealing any sensitive content, maintaining privacy while ensuring transparency. We encrypt your data and are compliant with most data privacy and compliance laws and standards. To learn more, take a look at how we add trust.'
            }
        ]
    },

    {
        key: FAQCategoryKey.AccountFAQs,
        title: 'Account FAQs',
        altTitle: 'Account Questions?',
        description: 'Questions about your Neotrust account?',
        items: [
            {
                question: 'How do I create a Neotrust account?',
                answer: 'To create an account, visit MyNeotrust.com. Click "Sign Up" and follow the prompts to enter your email, set a secure password, and confirm your details. Once your account is created, you’ll gain access to the Neotrust dashboard.'
            },
            {
                question: 'What if I forget my account password?',
                answer: 'If you forget your password, click the "Forgot Password" link on the login page. Enter your registered email address, and we’ll send you instructions to reset your password.'
            },
            {
                question: 'Can I change my account email address?',
                answer: 'Yes, you can update your account email from the account settings page. Go to the Neotrust dashboard, navigate to "Settings", and select "Update Email". You’ll need to verify the new email address to complete the process.'
            }
        ]
    },
    {
        key: FAQCategoryKey.PlansAndPricing,
        title: 'Plans and Pricing FAQs',
        altTitle: 'Trust Plans',
        description: 'Questions about your monthly Neotrust plan?',
        items: [
            {
                question: 'What plans does Neotrust offer?',
                answer: 'Neotrust offers flexible pricing plans to suit individual, business, and enterprise needs. Plans include Essential Trust for individuals, Advanced Trust for businesses, and Enterprise packages with team access and bulk processing features.'
            },
            {
                question: 'Is there a free trial available?',
                answer: 'No, Neotrust does not provide a free trial at this time.'
            },
            {
                question: 'Can I upgrade or downgrade my plan?',
                answer: 'Absolutely. You can adjust your subscription at any time from your account dashboard. Whether you need to add more features or switch to a smaller plan, changes will be applied on your next billing cycle.'
            },
            {
                question: 'Can I cancel my subscription at any time?',
                answer: 'Yes, you can cancel your subscription anytime through your dashboard. Your plan will remain active until the end of your current billing cycle, after which it will not renew.'
            },
            {
                question: 'Are there any hidden fees?',
                answer: 'No, Neotrust’s pricing is transparent. You’ll only be charged for the plan you select, with no unexpected fees or additional costs.'
            }
        ]
    }
];

export { FAQs, FAQCategoryKey };