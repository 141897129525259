import React, { useState, useCallback, useEffect } from 'react';
import UploadIcon from '../../../assets/v2/icons/icon-upload.svg';
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';
import { TOAST_TYPE, showToastUtil } from '../../../components/banner/BannerUtil';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../../../apiClient/NeoTrustAPI';
import { STATUS, handleErrorMsg } from '../../../utils/common';
import { useNavigate } from 'react-router-dom';
import { UserStatus } from '../../../utils/userUtils';
import { UserInfoContext } from '../../../components/UserInfoProvider';

interface FileMeta {
	fileName: string;
	fileHash: string;
	fileSize: number;
	fileLastModified: number;
}

const VerifyComponent: React.FC = () => {
	const { isAuthenticated, loginWithRedirect } = useAuth0();
	const api = API.getInstance(useAuth0()).getAxiosInstance(); // Pass auth0Context to API instance
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [loader, setLoader] = useState<STATUS>(STATUS.NONE);
	const navigate = useNavigate();

	const { userStatus } = React.useContext(UserInfoContext);

	const onDrop = useCallback((acceptedFiles: File[]) => {
		// Check if a valid .zip file is dropped
		if (!selectedFile && acceptedFiles.length === 1 && acceptedFiles[0].type === 'application/zip') {
			setSelectedFile(acceptedFiles[0]);
		} else {
			showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Please drop only one Neotrust .zip file.' });
		}
	}, []);

	const handleRemoveClick = () => {
		setSelectedFile(null);
	};

	const checkUserStatus = () => {
		if (!isAuthenticated) {
			showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Please sign in to your account.' });
			loginWithRedirect();
			return false;
		} else if (isAuthenticated) {
			if (userStatus === UserStatus.NO_USER) {
				showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Please sign up for an account.' });
				navigate('/onboarding');
				return false;
			} else if (userStatus === UserStatus.EXPIRED) {
				showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Your account has expired. Please renew your subscription.' });
				navigate('/onboarding?step=plan');
				return false;
			} else if (userStatus === UserStatus.KEY_READY) {
				showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Please complete your onboarding.' });
				navigate('/onboarding?step=plan');
				return false;
			} else if (userStatus === UserStatus.COMPLETE) {
				return true;
			}
		}
		return false;
	}

	const handleVerifyClick = async () => {
		if (!selectedFile) {
			showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Please select a Neotrust .zip file to verify Ownership and Content.' });
			return;
		}
		if (!checkUserStatus()) return;
		try {
			setLoader(STATUS.PROCESSING);
			// Read the contents of the selected .zip file
			const zipFileContent = await selectedFile.arrayBuffer();
			// Create a Blob object from the .zip file content
			const zipBlob = new Blob([zipFileContent], { type: 'application/zip' });

			// Unzip the .zip file using JSZip
			const jszip = new JSZip();
			const zip = await jszip.loadAsync(zipBlob);

			// Extract certificate.pdf from the zip
			const certificateFile = await zip.file('certificate.pdf')?.async('blob');
			if (!certificateFile) {
				showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Certificate.pdf not found in the zip.' });
				setLoader(STATUS.ERROR);
				return;
			}

			// Get the list of files from the 'files' folder in the zip and compute their metadata
			const filesMetaData: FileMeta[] = [];
			const filesFolder = zip.folder('files');
			if (filesFolder) {
				await Promise.all(
					Object.keys(filesFolder.files).map(async (fileName) => {
						// Skip if it's a folder or the certificate file
						if (fileName.endsWith('/') || fileName === 'certificate.pdf') {
							return;
						}
						// Remove "files/" prefix from the file name
						const cleanFileName = fileName.replace('files/', '');
						const file = filesFolder.files[fileName];
						const fileContent = await file.async('arraybuffer');

						// Compute SHA256 hash for the file
						const hashBuffer = await crypto.subtle.digest('SHA-256', fileContent);
						const fileHash = Array.from(new Uint8Array(hashBuffer))
							.map((byte) => byte.toString(16).padStart(2, '0'))
							.join('');

						filesMetaData.push({
							fileName: cleanFileName,
							fileHash: fileHash, // Add fileHash to the metadata
							fileSize: fileContent.byteLength,
							fileLastModified: file.date?.getTime() || 0,
						});
					})
				);
			}			// Create a FormData object to send the data to the API
			const formData = new FormData();
			formData.append('file', certificateFile, 'certificate.pdf');
			formData.append('filesMetaData', JSON.stringify(filesMetaData));

			// Send the data to the API using axios
			const response = await api.post(`/transaction/verifySignature`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				}
			});

			// Handle the response from the API as needed
			console.log(response.data);

			showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'Ownership verification successful!' });
			setLoader(STATUS.SUCCESS);
		} catch (e: any) {
			console.error('Error verifying ownership:', e);
			const errMsg = handleErrorMsg(e, 'Error verifying ownership. Please try again later.');
			showToastUtil({ status: TOAST_TYPE.ERROR, message: errMsg });
			setLoader(STATUS.ERROR);
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<div className="relative flex-auto justify-center bg-i-gray">
			<div className="w-full min-h-[500px] p-4 flex flex-col flex-auto flex-wrap items-center justify-center text-i-indigo z-10">

				<div
					{...getRootProps()}
					className={`z-10 w-full p-6 flex flex-col flex-auto justify-center cursor-pointer border-dashed border-4 border-i-indigo rounded-2xl ${isDragActive ? 'active' : ''
						}`}
				>
					<input {...getInputProps()} />
					<div className="flex flex-col items-center justify-center mb-6">
						<div className="w-full p-4 flex flex-col items-center justify-center text-2xl font-bold text-center">Verify Ownsership</div>
						<img className="h-12 w-auto" src={UploadIcon} alt="Upload" />
						<p className="leading-loose text-xl text-center whitespace-pre-wrap">
							{isDragActive
								? 'Drop the Neotrust .zip file here ...'
								: <>
									<span>Drag and drop your Neotrust .zip<br />file here or </span>
									<span className="underline">click</span>
									<span> to select files</span>
								</>
							}
						</p>
					</div>
					{selectedFile && (
						<div className="flex items-center justify-between px-3 py-2 my-1 bg-i-indigo text-white rounded-lg">
							<span>{selectedFile.name}</span>
							<button
								onClick={handleRemoveClick}
								className="text-red-400 font-bold focus:outline-none"
							>
								Remove
							</button>
						</div>
					)}
				</div>
				<div className="flex flex-col items-center justify-center mt-3 z-10">
					<button
						type="button"
						onClick={handleVerifyClick}
						className="ibtn ibtn-primary text-sm flex py-2 px-6 rounded-lg"
					>
						Verify Now
					</button>
				</div>
			</div>
			{
				(loader === STATUS.PROCESSING) && (
					<div className="absolute inset-0 flex items-center justify-center z-10 rounded-3xl bg-opacity-50 bg-bluegray-900">
						<div className="animate-spin rounded-full h-16 w-16 border-t-2 border-ntpurple"></div>
					</div>
				)
			}
		</div>
	);
};

export default VerifyComponent;
