import React from 'react';
import HeroImage from '../../assets/v2/images/faq.svg';

const HeroSection: React.FC = () => {
    return (
        <section className='bg-white'>
            <div className='container mx-auto p-8 text-center text-i-indigo'>
                <h1 className='text-4xl font-archivo'>
                    Digital Trust FAQ
                </h1>
                <h2 className='text-xl text-i-indigo-dark mt-4'>
                    {'Neotrust makes it simple to transform all your files into digital assets.'}
                </h2>
                <h2 className='text-xl text-i-indigo-dark mt-2'>
                    {'Have questions about the process? We have answers to them.'}
                </h2>
                <div className="w-full h-auto flex justify-center">
                    <img
                        src={HeroImage}
                        alt="Digital Trust FAQ"
                        className="w-full xl:-mt-20 lg:-mt-16 md:-mt-12 sm:-mt-8 -mt-4 xl:-mb-24 lg:-mb-20 md:-mb-16 sm:-mb-12 -mb-8"
                    />
                </div>

            </div>
        </section>
    );
};

export default HeroSection;
