import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import BgHomeHero from '../../assets/v2/containers/bg-home-hero.svg';
import BackgroundContainer from '../../components/common/BackgroundContainer';

const HeroSection: React.FC = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        // <div className='relative flex bg-i-indigo'>
        //     <div className='absolute inset-0 bg-no-repeat bg-cover bg-top z-0' style={{ backgroundImage: `url(${BgHomeHero})` }} ></div>
        //     <div className='container flex flex-wrap items-center mx-auto z-10'>
        //         <div className='w-full'>
        //             <div className='w-full lg:px-40 md:px-20 px-12 py-12'>
        //                 <div className='font-archivo lg:text-6xl text-5xl text-white py-4'>
        //                     Trust.<br />
        //                     Authority.<br />
        //                     Verification.
        //                 </div>
        //                 <div className='text-2xl text-white py-4'>
        //                     For all your digital files.
        //                 </div>
        //                 <button onClick={() => { loginWithRedirect() }} className='ibtn ibtn-secondary-alt py-3 px-6 text-2xl'>
        //                     Secure My Files
        //                 </button>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        //2xl: xl: lg: md: sm:
        <BackgroundContainer bgImage={BgHomeHero} aspectRatio='284/100' className="bg-i-indigo">
            <div className="container mx-auto flex flex-wrap items-center justify-center">
                <div className='w-full xl:px-40 lg:px-32 md:px-20 sm:px-8 px-4 py-12'>
                    <h1 className='font-archivo text-white py-4 2xl:text-8xl xl:text-7xl lg:text-6xl sm:text-5xl text-3xl'>
                        Trust.<br />
                        Authority.<br />
                        Verification.
                    </h1>
                    <div className='lg:text-3xl sm:text-2xl text-xl text-white py-4'>
                        For all your digital files.
                    </div>
                    <button onClick={() => { loginWithRedirect() }} className='ibtn ibtn-secondary-alt py-3 px-6 lg:text-3xl sm:text-2xl text-xl'>
                        Secure My Files
                    </button>
                </div>
            </div>
        </BackgroundContainer>
    );
};

export default HeroSection;
