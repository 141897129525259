import React, { useEffect, useState } from 'react';
import { FAQs, FAQCategoryKey } from './faqs';
import { useLocation } from 'react-router-dom';



const FAQsListSection: React.FC = () => {
    const location = useLocation();
    const [expandedIndex, setExpandedIndex] = useState<{ [key: string]: number | null }>({});

    const handleToggle = (key: FAQCategoryKey, index: number) => {
        setExpandedIndex((prev) => ({
            ...prev,
            [key]: prev[key] === index ? null : index
        }));
    };

    useEffect(() => {
        if (location.hash) {
            const targetId = location.hash.replace('#', '');
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                // targetElement.classList.add('animate-flash-highlight');

                // setTimeout(() => {
                //     targetElement.classList.remove('highlight');
                // }, 2000); // Remove highlight after 2 seconds
            }
        }
    }, [location.hash]);

    return (
        <div className="bg-white">
            <div className="container mx-auto p-8">
                <div className="space-y-8">
                    {FAQs.map(({ key, title, items }) => (
                        <div key={key} id={`faq-${key}`}>
                            <h2 className="text-2xl font-archivo text-i-indigo mb-4">{title}</h2>
                            <div className="space-y-4">
                                {items.map((faq, index) => (
                                    <div
                                        key={index}
                                        className={`border rounded-md p-4 shadow-sm ${expandedIndex[key] === index ? "bg-white" : "bg-gray-50"}`}
                                    >
                                        <div
                                            className="flex justify-between items-center cursor-pointer"
                                            onClick={() => handleToggle(key, index)}
                                        >
                                            <h3 className="text-lg font-archivo text-i-indigo">{faq.question}</h3>
                                            <span className="text-gray-500">
                                                {expandedIndex[key] === index ? "▲" : "▼"}
                                            </span>
                                        </div>
                                        {expandedIndex[key] === index && (
                                            <div className="mt-4 text-base text-i-gray-dark">
                                                {faq.answer}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQsListSection;
