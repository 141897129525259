import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import DashboardHeader from '../components/header/DashboardHeader';
import { BaseFooter } from '../components/footer/AppFooter';

const DashboardLayout: React.FC = () => {
    const { isAuthenticated, isLoading, error } = useAuth0();

    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!loaded && isLoading === false) {
            setLoaded(true);
        }
        console.log('isLoading, isAuthenticated, error', isLoading, isAuthenticated, error);
    }, [isLoading, isAuthenticated, error]);


    return (
        <>
            <div className='flex flex-col min-h-screen flex-auto'>
                <DashboardHeader />
                {!loaded ? <>
                    <div className='flex flex-auto items-center justify-center bg-i-gray'>
                        <div className='loader border-t-4 border-i-indigo rounded-full w-16 h-16 animate-spin'></div>
                    </div>
                </> : (error ? <>
                    <div className='flex flex-auto items-center justify-center bg-i-gray'>
                        <p className='text-red-500'>Authentication Error: {error && error.message}</p>
                    </div>
                </> : (isAuthenticated ? <>
                    <main className='flex flex-col flex-auto'>
                        <Outlet />
                    </main>
                </> : <>
                    <Navigate to='/' replace />
                </>))}
                <BaseFooter logo={true} />
            </div >
        </>
    );
};

export default DashboardLayout;
