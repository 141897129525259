import React from 'react';
import BgBlue from '../../assets/v2/containers/bg-blue.svg';
import ImageCompany from '../../assets/v2/images/img-company.svg';

const HeroSection: React.FC = () => {
    return (
        <section className='relative flex bg-i-indigo'>
            <div className='absolute inset-0 bg-no-repeat bg-cover bg-center z-0' style={{ backgroundImage: `url(${BgBlue})` }} ></div>
            <div className='container mx-auto flex flex-wrap justify-center items-center text-center z-10'>
                <div className='w-full text-white lg:px-12 md:px-8 px-4 py-12'>
                    <h1 className='font-archivo text-4xl'>
                        The digital trust standard.
                    </h1>
                    <h2 className='text-2xl py-6'>
                        Neotrust leverages blockchain technology to provide secure, transparent, and immutable verification for digital assets, identity, and ownership.
                    </h2>
                    <div className='w-full flex justify-center pb-12'>
                        <img src={ImageCompany} alt='Trust Hero' className='rounded-lg shadow-lg max-w-[1000px]' />
                    </div>
                    <button className='ibtn ibtn-secondary-alt py-3 px-6 text-2xl'>
                        Secure My Files
                    </button>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
