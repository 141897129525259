import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, ChartOptions, Plugin } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import API from '../../apiClient/NeoTrustAPI';
import { useAuth0 } from '@auth0/auth0-react';
import { FeatureUsage, TransactionTypeKeys, UserUtilizationAPIResponse } from '../../utils/types';
import { useEffect, useState } from 'react';
import LogoIcon from '../../assets/v2/logo/icon-reverse.svg';
import { useNavigate } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend);


export const getChartData = (dataArr: number[]) => {
	return {
		labels: ['Timestamps Used', 'Timestamps Available'],
		datasets: [
			{
				label: 'Count',
				data: dataArr,
				backgroundColor: [
					'#4B9BBE',
					'#D1E0CA',
				],
				borderColor: [
					'#4B9BBE',
					'#D1E0CA',
				],
				// hoverBackgroundColor: [
				// 	'#4B9BBE',
				// 	'#D1E0CA',
				// ],
				// hoverBorderColor: [
				// 	'#4B9BBE',
				// 	'#D1E0CA',
				// ],
			},
		],
	}
}

const options: ChartOptions<'doughnut'> = {
	responsive: true,
	plugins: {
		tooltip: {
			enabled: true,
			callbacks: {
				title: () => '',
				label: (tooltipItem) => {
					return ` ${tooltipItem.label}: ${tooltipItem.raw}`;
				},
			},
			bodyFont: {
				size: 12
			},
		},
		legend: {
			display: true,
			position: 'bottom',
			align: 'center',
			labels: {
				color: '#fff',
				font: {
					size: 12,
				},
			},
		},
	},
	cutout: '40%',
	rotation: 0,
	circumference: 360
}

interface UsageChartsProps {
	classes?: string;
}
const UsageCharts: React.FC<UsageChartsProps> = ({ classes }) => {
	const navigate = useNavigate();
	const api = API.getInstance(useAuth0()).getAxiosInstance(); // Pass auth0Context to API instance
	// const [loader, setLoader] = useState<STATUS>(STATUS.NONE);
	const [timestampUsage, setTimestampUsage] = useState<FeatureUsage>();
	const [timestampStoreUsage, setTimestampStoreUsage] = useState<FeatureUsage>();
	const [timestampChartData, setTimestampChartData] = useState<any>(getChartData([]));
	const [timestampStoreChartData, setTimestampStoreChartData] = useState<any>(getChartData([]));

	const getUserUtilization = async () => {
		try {
			const response = await api.get('/user/utilization');
			const data: UserUtilizationAPIResponse = response.data;
			// console.log('User Utilization:', data);
			if (data.data.length) {
				const allFeaturesUsage = data.data;
				allFeaturesUsage.forEach((value, i) => {
					if (value.featureName === TransactionTypeKeys.timestamp) {
						setTimestampUsage(value);
					}
					if (value.featureName === TransactionTypeKeys.timestamp_and_store) {
						setTimestampStoreUsage(value);
					}
				});
			}
			// setLoader(STATUS.SUCCESS);
		} catch (e: any) {
			console.error('Error fetching user utilization:', e);
			// showToastUtil({ status: TOAST_TYPE.ERROR, message: e?.message || 'Error fetching user info.' });
			// setLoader(STATUS.ERROR);
		}
	};

	useEffect(() => {
		getUserUtilization();
	}, []);

	useEffect(() => {
		const used = timestampUsage?.currentUsage ?? 0;
		const available = (timestampUsage?.maxQuota ?? 0) - used;
		const dataArr = [used, available];
		setTimestampChartData(getChartData(dataArr));
	}, [timestampUsage]);

	useEffect(() => {
		const used = timestampStoreUsage?.currentUsage ?? 0;
		const available = (timestampStoreUsage?.maxQuota ?? 0) - used;
		const dataArr = [used, available];
		setTimestampStoreChartData(getChartData(dataArr));
	}, [timestampStoreUsage]);


	return (<>
		<div className={`relative flex flex-col items-stretch justify-evenly bg-i-indigo text-white p-3 ${classes}`}>
			<div className='flex items-center justify-center'>
				<img className='w-auto h-8' src={LogoIcon} alt='' />
				<span className='pl-3 font-archivo text-xl'>{'Your Trust Vault'}</span>
			</div>
			<div className='flex flex-col justify-center items-center'>
				{
					// false && <>
					<UsageChart
						title='Stamps'
						data={timestampStoreChartData}
						options={options}
					/>
				}
				{false && <UsageChart
					title='Standard Timestamps'
					data={timestampChartData}
					options={options}
				/>}
			</div>
		</div>
	</>);
}

interface UsageChartProps {
	title: string;
	data: ChartData<'doughnut'>;
	options: ChartOptions<'doughnut'>;
}

const UsageChart: React.FC<UsageChartProps> = ({ title, data, options }) => (
	<div className='relative flex flex-col items-center justify-center '>
		<h3 className='py-2 font-semibold leading-6 text-center text-base text-white'>{title}</h3>
		<div className='w-[240px] overflow-auto'>
			<Doughnut data={data} options={options} />
		</div>
	</div>
);

export default UsageCharts;