import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const HeroSection: React.FC = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <section className="relative flex bg-i-indigo">
            <div className="container mx-auto p-8 text-white flex flex-col justify-center items-center text-center">
                <h1 className="text-4xl font-archivo py-4">Neotrust is securing the future</h1>
                <h2 className="text-2xl pb-6">All your assets secured and verified.</h2>
                <button
                    onClick={() => loginWithRedirect()}
                    className="ibtn ibtn-secondary-alt py-3 px-8 text-2xl"
                >
                    Secure My Files
                </button>
            </div>
        </section>
    );
};

export default HeroSection;
