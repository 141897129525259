import React from 'react';
import { useRoutes } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';

const AppRouter: React.FC = () => {
    const routing = useRoutes(AppRoutes);

    return <>{routing}</>;
}

export default AppRouter;
