import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './router/AppRouter';
import { Auth0ProviderComponent } from './providers/Auth0ProviderComponent';
import TrackingScripts from './providers/TrackingScripts';
import { UserInfoProvider } from './components/UserInfoProvider';
import { ConfigProvider } from 'antd';
import { ToastContainer } from 'react-toastify';

const App: React.FC = () => {
	{/* use only app level higher-order components or providers here. */ }
	return (
		<Router>

			<TrackingScripts />

			<Auth0ProviderComponent>
				<UserInfoProvider>
					<ConfigProvider theme={{
						token: {
							fontFamily: 'Varela Round',
						},
					}}>
						<AppRouter />
						<ToastContainer position="top-right" newestOnTop={true} />
					</ConfigProvider>
				</UserInfoProvider>
			</Auth0ProviderComponent>

		</Router>
	);
}

export default App;
