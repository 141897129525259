import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import AppLayout from '../layouts/AppLayout';
import DashboardLayout from '../layouts/DashboardLayout';
import HomePage from '../pages/home/HomePage';
import TrustPage from '../pages/trust/TrustPage';
import PlansPage from '../pages/plans/PlansPage';
import IndustriesPage from '../pages/industries/IndustriesPage';
import CompanyPage from '../pages/company/CompanyPage';
import FAQsPage from '../pages/faqs/FAQsPage';
import ContactPage from '../pages/contact/ContactPage';
import DashboardPage from '../pages/dashboard/DashboardPage';
import StampPage from '../pages/dashboard/stamp/StampPage';
import VerifyPage from '../pages/dashboard/verify/VerifyPage';
import ProfilePage from '../pages/profile/ProfilePage';

import OnBoardingPage from '../pages/onboarding/OnBoardingPage';
import Download from '../pages/download/Download';
import PrivacyPolicy from '../pages/privacy-policy/PrivacyPolicy';
import TermsAndConditions from '../pages/terms-and-conditions/TermsAndConditions';
import LoginChecker from '../pages/auth/LoginChecker';
import PaymentSuccess from '../pages/onboarding/PaymentSuccess';
import PaymentCancelled from '../pages/onboarding/PaymentCancelled';

export const AppRoutes: RouteObject[] = [
    {
        path: '/',
        element: <AppLayout />,
        children: [
            { index: true,  element: <HomePage /> },
            { path: 'trust', element: <TrustPage /> },
            { path: 'plans', element: <PlansPage /> },
            { path: 'industries', element: <IndustriesPage /> },
            { path: 'company', element: <CompanyPage /> },
            { path: 'faqs', element: <FAQsPage /> },
            { path: 'contact', element: <ContactPage /> },
            // below pages to be redesigned
            { path: 'onboarding', element: <OnBoardingPage /> },
            { path: 'download/:transactionId', element: <Download /> },
            { path: 'privacy-policy', element: <PrivacyPolicy /> },
            { path: 'terms-and-conditions', element: <TermsAndConditions /> },

            { path: 'login-checker', element: <LoginChecker /> },
            { path: 'payment/success', element: <PaymentSuccess /> },
            { path: 'payment/canceled', element: <PaymentCancelled /> },
            // Redirect any unknown routes to the home page
            { path: '*', element: <Navigate to='/' replace /> },
        ],
    },
    {
        path: '/dashboard',
        element: <DashboardLayout />,
        children: [
            { index: true, element: <DashboardPage /> },
            { path: 'overview', element: <DashboardPage /> },
            { path: 'portfolio', element: <Navigate to="overview" replace /> },
            { path: 'stamp', element: <StampPage /> },
            { path: 'verify', element: <VerifyPage /> },
            { path: 'profile', element: <ProfilePage /> },
            { path: '*', element: <Navigate to='overview' replace /> },
          ],
      
    },
];
