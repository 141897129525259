import React from 'react';
import { FAQCategoryKey, FAQs } from './faqs';
import ntWhatImg from '../../assets/v2/icons/faqs/nt-what.svg';
import ntWhoImg from '../../assets/v2/icons/faqs/nt-who.svg';
import ntTimestampImg from '../../assets/v2/icons/faqs/nt-timestamp.svg';
import ntVerifyImg from '../../assets/v2/icons/faqs/nt-verify.svg';
import ntAccountImg from '../../assets/v2/icons/faqs/nt-account.svg';
import ntPlansImg from '../../assets/v2/icons/faqs/nt-plans.svg';
import { useNavigate } from 'react-router-dom';

const FAQsFeaturesSection: React.FC = () => {

    const getImageForCategory = (key: FAQCategoryKey): string => {
        switch (key) {
            case FAQCategoryKey.WhoIsNeotrust:
                return ntWhoImg;
            case FAQCategoryKey.HowToTimestamp:
                return ntTimestampImg;
            case FAQCategoryKey.HowToVerify:
                return ntVerifyImg;
            case FAQCategoryKey.AccountFAQs:
                return ntAccountImg;
            case FAQCategoryKey.PlansAndPricing:
                return ntPlansImg;
            case FAQCategoryKey.WhatIsNeotrust:
            default:
                return ntWhatImg;
        }
    };

    const navigate = useNavigate();

    const handleNavigate = (key: FAQCategoryKey) => {
        navigate(`#faq-${key}`); // Navigate to the hash
    };

    return (
        <section className="relative flex bg-white">
            <div className="container mx-auto text-center py-12 lg:px-12 md:px-8 px-4">

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
                    {FAQs.map(({ key, title, altTitle, description }) => (

                        <div key={key} className="flex flex-col items-center text-center">
                            <img
                                src={getImageForCategory(key)}
                                alt={`${altTitle ?? title} Image`}
                                className="w-auto h-32 mb-4 rounded-lg object-cover"
                            />
                            <h2 className="text-2xl font-archivo text-i-indigo mb-4">{altTitle ?? title}</h2>
                            <h3 className="text-lg text-i-gray-dark mb-4 px-2">{description}</h3>
                            <div className="mt-auto">
                                <button onClick={() => handleNavigate(key)} className='ibtn ibtn-secondary py-3 px-6 text-xl'>{'Learn More'}</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQsFeaturesSection;
