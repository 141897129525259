import React, { useState } from 'react';
import { showToastUtil, TOAST_TYPE } from '../../components/banner/BannerUtil';
import { EnvConfig } from '../../utils/envConfig';
import IconFB from '../../assets/v2/icons/social-icon-fb.svg';
import IconLinkedin from '../../assets/v2/icons/social-icon-linkedin.svg';
import IconIG from '../../assets/v2/icons/social-icon-ig.svg';
import IconX from '../../assets/v2/icons/social-icon-x.svg';
import IconMedium from '../../assets/v2/icons/social-icon-medium.svg';
import IconAddress from '../../assets/v2/icons/icon-address.svg';
import IconCall from '../../assets/v2/icons/icon-call.svg';
import IconEmail from '../../assets/v2/icons/icon-email.svg';
import Image1 from '../../assets/v2/images/img-trust-1.svg';

export default function ContactPage() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
    });
    const [errors, setErrors] = useState<Record<string, string>>({});

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (validateFields()) {
            try {
                const response = await fetch(`${EnvConfig.API_DOMAIN}/contact`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'Message sent successfully!' });
                } else {
                    const data = await response.json();
                    showToastUtil({ status: TOAST_TYPE.ERROR, message: 'An error occurred while submitting the form. Please try again.' });
                }
            } catch (error) {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: 'An error occurred while submitting the form. Please try again.' });
            }
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const validateFields = () => {
        let newErrors: Record<string, string> = {};

        if (!formData.firstName) newErrors.firstName = 'First name is required';
        if (!formData.lastName) newErrors.lastName = 'Last name is required';
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (!emailRegex.test(formData.email)) {
                newErrors.email = 'Invalid email format';
            }
        }
        if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone number is required';
        if (!formData.message) newErrors.message = 'Message is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <section className='bg-white py-12'>
            <div className='container mx-auto px-6 lg:px-16'>
                <h1 className='text-center text-4xl font-archivo text-i-indigo mb-4'>We’re here to help you build digital trust.</h1>
                <p className='text-center text-2xl text-i-indigo-dark mb-4'>
                    {'Don’t hesitate to get in touch. We’re here to answer any questions you may have and to provide you with the information you need to make informed decisions.'}
                </p>
                <div className='flex space-x-8 justify-center mb-8'>
                    <a href='https://www.facebook.com/people/MyNeotrust/61556487141298/' target='_blank' rel='noreferrer' className='hover:opacity-75'>
                        <img src={IconFB} alt='Facebook' className='w-auto h-8' />
                    </a>
                    <a href='https://www.linkedin.com/company/myneotrust/' target='_blank' rel='noreferrer' className='hover:opacity-75'>
                        <img src={IconLinkedin} alt='LinkedIn' className='w-auto h-8' />
                    </a>
                    <a href='https://www.instagram.com/myneotrust/' target='_blank' rel='noreferrer' className='hover:opacity-75'>
                        <img src={IconIG} alt='Instagram' className='w-auto h-8' />
                    </a>
                    <a href='https://twitter.com/MyNeotrust/' target='_blank' rel='noreferrer' className='hover:opacity-75'>
                        <img src={IconX} alt='X (Twitter)' className='w-auto h-8' />
                    </a>
                    <a href='https://medium.com/@MyNeotrust/' target='_blank' rel='noreferrer' className='hover:opacity-75'>
                        <img src={IconMedium} alt='Medium' className='w-auto h-8' />
                    </a>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-12'>
                    {/* Left Column */}
                    <div className='flex justify-center items-center'>
                        <img
                            src={Image1}
                            alt='Contact Us'
                            className=' object-cover h-full rounded-lg shadow-md'
                        />
                    </div>

                    {/* Right Column */}
                    <form
                        onSubmit={handleSubmit}
                        className='bg-[#E1E3EF] p-8 rounded-lg shadow-md text-i-indigo'
                    >
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
                            <div>
                                <label
                                    htmlFor='firstName'
                                    className='block text-sm font-semibold '
                                >
                                    First Name
                                </label>
                                <input
                                    type='text'
                                    id='firstName'
                                    name='firstName'
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    className='mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-i-indigo'
                                />
                                {errors.firstName && (
                                    <p className='text-red-500 text-sm'>{errors.firstName}</p>
                                )}
                            </div>
                            <div>
                                <label
                                    htmlFor='lastName'
                                    className='block text-sm font-semibold '
                                >
                                    Last Name
                                </label>
                                <input
                                    type='text'
                                    id='lastName'
                                    name='lastName'
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    className='mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-i-indigo'
                                />
                                {errors.lastName && (
                                    <p className='text-red-500 text-sm'>{errors.lastName}</p>
                                )}
                            </div>
                        </div>
                        <div className='mt-6'>
                            <label
                                htmlFor='email'
                                className='block text-sm font-semibold '
                            >
                                Email
                            </label>
                            <input
                                type='email'
                                id='email'
                                name='email'
                                value={formData.email}
                                onChange={handleInputChange}
                                className='mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-i-indigo'
                            />
                            {errors.email && (
                                <p className='text-red-500 text-sm'>{errors.email}</p>
                            )}
                        </div>
                        <div className='mt-6'>
                            <label
                                htmlFor='phoneNumber'
                                className='block text-sm font-semibold '
                            >
                                Phone Number
                            </label>
                            <input
                                type='text'
                                id='phoneNumber'
                                name='phoneNumber'
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                className='mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-i-indigo'
                            />
                            {errors.phoneNumber && (
                                <p className='text-red-500 text-sm'>{errors.phoneNumber}</p>
                            )}
                        </div>
                        <div className='mt-6'>
                            <label
                                htmlFor='message'
                                className='block text-sm font-semibold '
                            >
                                Message
                            </label>
                            <textarea
                                id='message'
                                name='message'
                                rows={5}
                                value={formData.message}
                                onChange={handleInputChange}
                                className='mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-i-indigo'
                            />
                            {errors.message && (
                                <p className='text-red-500 text-sm'>{errors.message}</p>
                            )}
                        </div>
                        <button
                            type='submit'
                            // className='mt-6 bg-i-indigo text-white font-semibold py-2 px-4 rounded-lg hover:bg-i-indigo-dark transition'
                            className='mt-6 min-w-[180px] ibtn ibtn-secondary py-3 px-6'
                        >
                            Send
                        </button>
                    </form>
                </div>
                {/* Bottom Section */}
                <div className='mt-12 border-t border-i-indigo pt-6'>
                    <div className='container mx-auto px-6 lg:px-16 grid grid-cols-1 sm:grid-cols-3 gap-6 text-center'>
                        {/* Address Column */}
                        <div>
                            <div className='flex flex-col items-center'>
                                <span className='icon-placeholder mb-4'>
                                    <img src={IconAddress} alt='Address' className='w-auto h-8' />
                                </span>
                                <p className='text-i-indigo-dark text-lg font-semibold mb-2'>United States:</p>
                                <p className='text-gray-600 text-base mb-4'>701 Ellicott Street, Buffalo, NY, 14203</p>
                                <p className='text-i-indigo-dark text-lg font-semibold mb-2'>Canada:</p>
                                <p className='text-gray-600 text-base'>4514 Queen St, Niagara Falls, ON L2E 6S8</p>
                            </div>
                        </div>

                        {/* Phone Column */}
                        <div>
                            <div className='flex flex-col items-center'>
                                <span className='icon-placeholder mb-4'>
                                    <img src={IconCall} alt='Address' className='w-auto h-8' />
                                </span>
                                <p className='text-i-indigo-dark text-lg font-semibold mb-2'>United States:</p>
                                <p className='text-gray-600 text-base mb-4'>407-754-5915</p>
                                <p className='text-i-indigo-dark text-lg font-semibold mb-2'>Canada:</p>
                                <p className='text-gray-600 text-base'>###-###-####</p>
                            </div>
                        </div>

                        {/* Email Column */}
                        <div>
                            <div className='flex flex-col items-center'>
                                <span className='icon-placeholder mb-4'>
                                    <img src={IconEmail} alt='Address' className='w-auto h-8' />
                                </span>
                                <p className='text-i-indigo-dark text-lg font-semibold mb-2'>General Inquiries:</p>
                                <p className='text-gray-600 text-base mb-4'>
                                    <a href='mailto:hello@myneotrust.com' className='hover:text-i-indigo-dark'>
                                        hello@myneotrust.com
                                    </a>
                                </p>
                                <p className='text-i-indigo-dark text-lg font-semibold mb-2'>Technical Inquiries:</p>
                                <p className='text-gray-600 text-base'>
                                    <a href='mailto:support@myneotrust.com' className='hover:text-i-indigo-dark'>
                                        support@myneotrust.com
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}
