import React from 'react';
import HeroSection from './HeroSection';
import ContentSection from './ContentSection';
import WhyNeotrustSection from '../../components/common/WhyNeotrustSection';

const IndustriesPage: React.FC = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <HeroSection />
            <ContentSection />
            <WhyNeotrustSection />
        </div>
    );
};

export default IndustriesPage;
