import ProfileComponent from './ProfileComponent';
import WhyNeotrustSection from '../../components/common/WhyNeotrustSection';
import UpgradePlanSection from '../../components/common/UpgradePlanSection';


const ProfilePage: React.FC = () => {

    return (<>
            <ProfileComponent />
            <div className='w-full flex flex-col h-4 bg-white relative'></div>
            <UpgradePlanSection />
            <div className='w-full flex flex-col h-4 bg-white relative'></div>
    </>);
}

export default ProfilePage;