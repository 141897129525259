import React, { useEffect, useState } from 'react';
import { UserAddress, UserProfileAddress } from '../../utils/types';
import ProfileDetailsForm from '../onboarding/ProfileDetailsForm';
import { useAuth0 } from '@auth0/auth0-react';
import { TOAST_TYPE, showToastUtil } from '../../components/banner/BannerUtil';
import { handleErrorMsg } from '../../utils/common';
import { UserInfoContext } from '../../components/UserInfoProvider';
import { UserInfo } from '../../utils/userUtils';
import API from '../../apiClient/NeoTrustAPI';
import LogoIcon from '../../assets/v2/logo/logo-icon.svg';

const ProfileComponent: React.FC = () => {
    const { logout } = useAuth0();
    const api = API.getInstance(useAuth0()).getAxiosInstance(); // Pass auth0Context to API instance

    const { userInfoData, refreshUserInfo } = React.useContext(UserInfoContext);

    const [userInfo, setUserInfo] = useState<UserInfo>();
    const [userProfileAddress, setUserProfileAddress] = useState<UserProfileAddress>({
        fullName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
    });
    const [isEditView, setEditView] = useState<boolean>(false);

    useEffect(() => {
        if (!userInfoData) return;
        const userInfo = userInfoData.user[0];
        setUserInfo(userInfo);
        const address: UserAddress = userInfo?.address[0];
        setUserProfileAddress({
            // ...userProfileAddress,
            fullName: userInfo?.userName || '',
            ...address,
        });
    }, [userInfoData]);

    const onEditClick = () => {
        setEditView(true);
    };

    const handleUserProfileAddress = async (profileAddress: UserProfileAddress) => {
        try {
            const response = await api.put('/user/address', {
                ...profileAddress
            });
            refreshUserInfo();
            // setUserProfileAddress(profileAddress);
            setEditView(false);
        } catch (e: any) {
            const errMsg = handleErrorMsg(e, 'Error updating user profile.');
            showToastUtil({ status: TOAST_TYPE.ERROR, message: errMsg });
        }
    };

    const onPaymentPortalClick = async () => {
        try {
            const response = await api.post('/payment/create-customer-portal-session');
            const responseData = response.data;
            responseData.url && window.open(responseData.url, '_blank'); // Open URL in a new tab/window            return;
            return;
        } catch (e: any) {
            console.error('Error creating payment portal session:', e);
            const errMsg = handleErrorMsg(e, 'Error creating payment portal session.');
            showToastUtil({ status: TOAST_TYPE.ERROR, message: errMsg });
        }
    };

    return (
        <div className='container mx-auto text-i-indigo px-6 lg:px-12 py-8'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                {/* Profile Card */}
                <div className='bg-i-gray-light rounded-xl shadow-lg p-6'>
                    {isEditView ? (
                        <ProfileDetailsForm
                            initValue={userProfileAddress}
                            handleUserProfileAddress={handleUserProfileAddress}
                            onNextStep={() => { }}
                        />
                    ) : (
                        <>
                            <h2 className='text-2xl font-archivo mb-6 flex items-center'>
                                <img src={LogoIcon} alt='Neotrust' className='h-12 w-12 mr-2' />
                                {'Your Profile'}
                            </h2>
                            <div className='space-y-4'>
                                {[
                                    { label: 'Full Name', value: userProfileAddress.fullName },
                                    { label: 'Address Line 1', value: userProfileAddress.addressLine1 },
                                    { label: 'Address Line 2', value: userProfileAddress.addressLine2 || '' },
                                    { label: 'City', value: userProfileAddress.city },
                                    { label: 'State/Province', value: userProfileAddress.state },
                                    { label: 'Country', value: userProfileAddress.country },
                                    { label: 'ZIP/Postal Code', value: userProfileAddress.zipCode },
                                ].map((item, index) => (
                                    <div key={index} className="truncate">
                                        <span className="font-semibold">{item.label}:</span>
                                        <span className="ml-4 text-xl text-i-indigo-dark" title={item.value}>{item.value}</span>
                                    </div>
                                ))}
                            </div>
                            <button onClick={onEditClick} className='ibtn ibtn-primary mt-4 py-2 px-6 text-xl'>{'Edit'}</button>
                        </>
                    )}
                </div>

                {/* Portfolio and Payment Info */}
                <div className='space-y-6'>
                    {/* Portfolio Card */}
                    <div className='bg-i-gray-light rounded-xl shadow-lg p-6'>
                        <h2 className='text-2xl font-archivo mb-6 flex items-center'>{'Your Portfolio'}</h2>
                        <div className='space-y-3'>
                            {[
                                { label: 'Blockchain Address (Bitcoin)', value: userInfo?.bChainAddress },
                                // { label: 'Plan', value: userInfo?.plan_name },
                            ].map((item, index) => (
                                <div key={index}>
                                    <div className="font-semibold truncate">{item.label}:</div>
                                    <div className="text-xl text-i-indigo-dark truncate" title={item.value}>{item.value}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Manage Billing Card */}
                    <div className='bg-i-gray-light rounded-xl shadow-lg p-6'>
                        <h2 className='text-2xl font-archivo mb-4 flex items-center'>{'Manage Payment'}</h2>
                        <p className='text-i-indigo-dark'>{'Payment & billing info portal.'}</p>
                        <button onClick={onPaymentPortalClick} className='ibtn ibtn-primary mt-4 py-2 px-6 text-xl'>{'Manage'}</button>
                    </div>
                    {/* Upgrade Plan Card */}
                    <div className='bg-i-indigo text-white rounded-xl shadow-lg p-6'>
                        <h2 className='text-2xl font-archivo mb-4 flex items-center'>{'Upgrade Plan'}</h2>
                        <div className="truncate">{'Plan:'}
                            <span className="ml-4 font-semibold text-xl" title={userInfo?.plan_name}>{userInfo?.plan_name}</span>
                        </div>
                        <button onClick={onPaymentPortalClick} className='ibtn ibtn-secondary-alt mt-4 py-2 px-6 text-xl'>{'Upgrade'}</button>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ProfileComponent;
