import React from 'react';
import { Link } from 'react-router-dom';
import CTAImage from '../../assets/v2/images/home-cta2.svg';
import BGImage from '../../assets/v2/containers/bg-blue-2.svg';
import { useAuth0 } from '@auth0/auth0-react';

const DigitalTrustSection: React.FC = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <section className='bg-white py-12'>
            <div className='container mx-auto grid grid-cols-1 md:grid-cols-2 gap-2 px-4 items-stretch'>
                {/* Left Column - Digital Trust Info */}
                <div className='flex flex-col justify-center'>
                    <div className='flex flex-col justify-center bg-[#E1E3EF] text-i-indigo-dark p-6 rounded-lg'>
                        <h2 className='xl:text-5xl lg:text-4xl sm:text-3xl text-2xl font-archivo text-i-indigo mb-4'>
                            Digital trust means
                        </h2>
                        <p className='lg:text-2xl text-xl mb-4'>
                            {'Your files are secure, authentic, and verifiable at all times. Your data cannot be tampered with, altered, or misrepresented. What you’ve created or stored remains unchanged and is verifiable.'}
                        </p>
                        <ul className='grid grid-cols-2 gap-2 lg:text-2xl text-xl list-disc ml-4 mb-4'>
                            <li>Security</li>
                            <li>Ownership</li>
                            <li>Verification</li>
                            <li>Compliance</li>
                            <li>Auditable</li>
                            <li>Unalterable</li>
                        </ul>
                        <Link to='/trust' className='text-xl py-2 inline-flex font-archivo underline'>{'Learn more about trust.'}</Link>
                    </div>

                    <div className='text-white p-6 rounded-lg mt-2 bg-cover bg-center'
                        style={{ backgroundImage: `url(${BGImage})` }}
                    >
                        <h3 className='lg:text-4xl sm:text-3xl text-2xl font-archivo mb-4'>
                            Secure your data, protect your creations, and ensure authenticity
                        </h3>
                        <button onClick={() => { loginWithRedirect() }} className='ibtn ibtn-secondary-alt py-3 px-6 lg:text-3xl sm:text-2xl text-xl'>
                            Sign Up Now
                        </button>
                    </div>
                </div>

                {/* Right Column */}
                <div className="flex items-center justify-center rounded-lg h-auto md:h-full">
                    <img
                        src={CTAImage}
                        alt='Digital Trust'
                        className='w-full h-full object-cover object-left-top rounded-lg xl:max-h-[680px] 2xl:max-h-[640px]'
                    />
                </div>
            </div>
        </section>
    );
};

export default DigitalTrustSection;
