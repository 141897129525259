import React from 'react';
import Image1 from '../../assets/v2/images/img-trust-1.svg';
import Image2 from '../../assets/v2/images/img-trust-2.svg';
import { useAuth0 } from '@auth0/auth0-react';

const DIGITAL_SECURITY_POINTS_I = [
    {
        title: "Secure Your Ownership",
        content:
            "With Neotrust, your digital files are permanently linked to your verified identity. This ensures that your work is always credited to you, providing indisputable proof of ownership. Whether you're a content creator, designer, or business owner, Neotrust safeguards your intellectual property from theft and unauthorized claims.",
    },
    {
        title: "Prevent Unauthorized Modifications",
        content:
            "Once registered with Neotrust, your files are locked on an immutable blockchain, making any attempt to alter or tamper with them detectable. This layer of security is essential for protecting sensitive documents, contracts, and creative work, ensuring their integrity remains intact over time.",
    },
    {
        title: "Establish Trust and Transparency",
        content:
            "Neotrust allows you to provide verifiable proof of your work's origin, creation date, and ownership. Whether you're negotiating contracts, selling digital art, or collaborating on projects, Neotrust's timestamping and verification give others confidence in the authenticity and security of your files.",
    },
];

const DIGITAL_SECURITY_POINTS_II = [
    {
        title: "Avoid Legal Disputes",
        content:
            "Disputes over intellectual property and file authenticity can be costly and time-consuming. Neotrust's instant timestamping feature provides clear evidence of when your files were created, offering strong protection in any legal challenge or ownership dispute. This gives you peace of mind and a robust defense when needed.",
    },
    {
        title: "Empower Your Digital Presence",
        content:
            "By incorporating Neotrust into your workflow, you build trust and credibility in the digital space. Your work will be verified and easily traceable, making it easier for others to trust your brand, creations, or services. It's a key step in positioning yourself or your business as a trusted, secure entity online.",
    },
];


const TrustPointsSection: React.FC = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <section className='relative flex bg-white'>
            <div className='container mx-auto px-4 p-8 text-i-indigo'>
                {/* Grid layout for larger screens, stacks vertically on smaller screens */}
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                    {/* Top Left */}
                    <div className='flex flex-col items-center justify-center order-1 lg:order-1'>
                        <img
                            src={Image1}
                            alt='People working together'
                            className='object-cover rounded-t-lg w-full'
                        />
                        <div className='w-full bg-i-indigo text-white text-center px-3 py-6 rounded-b-lg'>
                            {'Neotrust is the new standard for securing and verifying digital assets.'}
                        </div>
                    </div>

                    {/* Top Right */}
                    <div className='flex flex-col justify-start p-6 order-2 lg:order-2'>
                        <h2 className='text-4xl font-archivo mb-8'>{'Secure your digital future'}</h2>

                        <p className='xl:text-2xl text-xl mb-4'>
                            Neotrust ensures that your digital files and assets are secure, verifiable, and trusted. Neotrust solves the big problems you've always had in the digital world.
                        </p>
                        <ul className='pl-3 space-y-4'>
                            {DIGITAL_SECURITY_POINTS_I.map((point, index) => (
                                <li key={index}>
                                    <div className='2xl:text-2xl text-lg font-bold'>{index + 1}. {point.title}:</div>
                                    <div className='2xl:text-xl text-i-indigo-dark'>{point.content}</div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Bottom Left */}
                    <div className='flex flex-col justify-start p-6 order-4 lg:order-3'>
                        <ul className='pl-3 space-y-4'>
                            {DIGITAL_SECURITY_POINTS_II.map((point, index) => (
                                <li key={index}>
                                    <div className='2xl:text-2xl text-lg font-bold'>{index + 4}. {point.title}:</div>
                                    <div className='2xl:text-xl text-i-indigo-dark'>{point.content}</div>
                                </li>
                            ))}
                        </ul>
                        <div className='flex justify-center mt-6'>
                            <button onClick={() => { loginWithRedirect() }} className='ibtn ibtn-secondary-alt py-2 px-6 text-xl'>
                                Secure My Files
                            </button>
                        </div>
                    </div>

                    {/* Bottom Right */}
                    <div className='flex flex-col items-center justify-center order-3 lg:order-4'>
                        <img
                            src={Image2}
                            alt='Man using Neotrust'
                            className='object-cover rounded-t-lg w-full'
                        />
                        <div className='w-full bg-i-indigo text-white text-center px-3 py-6 rounded-b-lg'>
                            {'From creators to large enterprises, Neotrust guarantees that your work is securely linked to your identity with tamper-proof timestamps.'}
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default TrustPointsSection;
