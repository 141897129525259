import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import BgHomeHero from '../../assets/v2/containers/bg-industries-hero.svg';
import BackgroundContainer from '../../components/common/BackgroundContainer';

const HeroSection: React.FC = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <BackgroundContainer bgImage={BgHomeHero} aspectRatio='27/8' className="bg-i-indigo">
            <div className="container mx-auto flex flex-wrap items-center justify-center">
                <div className='w-full xl:px-40 lg:px-32 md:px-20 sm:px-8 px-4 py-12'>
                    <h1 className='font-archivo text-white py-4 2xl:text-6xl xl:text-6xl lg:text-5xl sm:text-4xl text-3xl'>
                        Adding Trust<br />
                        to the supply chain<br />
                        and manufacturing
                    </h1>
                    <div className='lg:text-3xl sm:text-2xl text-xl text-white py-4'>
                        Securing your digital future
                    </div>
                    <button onClick={() => { loginWithRedirect() }} className='ibtn ibtn-secondary-alt py-3 px-6 lg:text-3xl sm:text-2xl text-xl'>
                        Secure My Files
                    </button>
                </div>
            </div>
        </BackgroundContainer>
    );
};

export default HeroSection;
