import React from 'react';
import VerifyComponent from './VerifyComponent';
import UsageCharts from '../UsageCharts';
import UpgradePlanSection from '../../../components/common/UpgradePlanSection';

const VerifyPage: React.FC = () => {
    return (
        <>
            <div className="w-full flex flex-col md:flex-row bg-i-gray relative">
                <UsageCharts classes='rounded-xl md:rounded-l-none md:rounded-r-xl m-3 md:mx-0' />
                <VerifyComponent />
            </div>
            <div className="w-full flex flex-col h-4 bg-white relative"></div>
            <UpgradePlanSection />
            <div className="w-full flex flex-col h-4 bg-white relative"></div>
            {/* <div className="w-full flex flex-col flex-auto bg-i-gray relative"></div> */}
        </>
    );
};

export default VerifyPage;
