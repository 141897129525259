import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import BgHomeCta from '../../assets/v2/containers/bg-home-cta.svg';
import IconProtect from '../../assets/v2/icon-protect.svg';
import IconClock from '../../assets/v2/icon-clock.svg';
import IconProfile from '../../assets/v2/icon-profile.svg';

const CTASection: React.FC = () => {
    const { loginWithRedirect } = useAuth0();
    const [formData, setFormData] = useState({ FNAME: '', EMAIL: '' });
    const [error, setError] = useState<string | null>(null);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Validate email format
        if (!emailRegex.test(formData.EMAIL)) {
            setError('Please enter a valid email address.');
            return;
        }

        setError(null);
        e.currentTarget.submit(); // Proceed with the form submission
    };

    return (
        <section className='relative flex bg-i-indigo'>
            {/* Background Image */}
            <div
                className='hidden sm:block absolute inset-0 bg-no-repeat bg-cover bg-top z-0'
                style={{ backgroundImage: `url(${BgHomeCta})` }}
            ></div>

            <div className='container mx-auto flex flex-wrap items-center py-12 lg:px-8 sm:px-6 px-8 z-10'>
                <div className='w-full flex flex-row'>
                    <div className='sm:pl-[52%] text-white flex flex-col justify-around'>
                        {/* Heading */}
                        <h2 className='xl:text-6xl lg:text-5xl sm:text-3xl text-2xl font-archivo'>
                            {'Never worry about file ownership again'}
                        </h2>

                        {/* Features List */}
                        <ul className='lg:text-3xl sm:text-2xl text-base py-4'>
                            <li className='flex items-center sm:py-6 py-2'>
                                <img className='sm:w-12 w-8 h-auto' src={IconProtect} alt='' />
                                <h3 className='lg:pl-12 sm:pl-8 pl-4'>
                                    {'Protect your IP for its entire lifecycle'}
                                </h3>
                            </li>
                            <li className='flex items-center sm:py-6 py-2'>
                                <img className='sm:w-12 w-8 h-auto' src={IconClock} alt='' />
                                <h3 className='lg:pl-12 sm:pl-8 pl-4'>
                                    {'Securely authenticate your work and timestamp with a single click'}
                                </h3>
                            </li>
                            <li className='flex items-center sm:py-6 py-2'>
                                <img className='sm:w-12 w-8 h-auto' src={IconProfile} alt='' />
                                <h3 className='lg:pl-12 sm:pl-8 pl-4'>
                                    {'Tie your work to your identity, and clarify and certify ownership'}
                                </h3>
                            </li>
                        </ul>

                        {/* Subheading */}
                        <h3 className='xl:text-5xl lg:text-4xl sm:text-2xl text-xl font-archivo pb-6'>
                            {'Start securing files today!'}
                        </h3>

                        {/* Mailchimp Form */}
                        <div className='flex flex-col'>
                            <form
                                className='flex flex-col space-y-4 lg:text-2xl sm:text-xl text-base'
                                action='https://myneotrust.us12.list-manage.com/subscribe/post?u=e23132eef5794f36db505272a&amp;id=0679453965'
                                method='POST'
                                target='_self'
                                onSubmit={handleSubmit}
                            >
                                {/* Name Field */}
                                <div className='flex flex-col'>
                                    <label htmlFor='FNAME' className='font-medium'>
                                        Name:
                                    </label>
                                    <input
                                        type='text'
                                        id='FNAME'
                                        name='FNAME'
                                        value={formData.FNAME}
                                        onChange={handleChange}
                                        className='mt-2 p-3 text-i-indigo-dark rounded-md border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:outline-none'
                                        placeholder='Enter your name'
                                        required
                                    />
                                </div>

                                {/* Email Field */}
                                <div className='flex flex-col'>
                                    <label htmlFor='EMAIL' className='font-medium'>
                                        Email:
                                    </label>
                                    <input
                                        type='email'
                                        id='EMAIL'
                                        name='EMAIL'
                                        value={formData.EMAIL}
                                        onChange={handleChange}
                                        className='mt-2 p-3 text-i-indigo-dark rounded-md border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:outline-none'
                                        placeholder='Enter your email'
                                        required
                                    />
                                    {error && <p className='text-red-500 text-sm mt-2'>{error}</p>}
                                </div>

                                {/* Honeypot Field (Hidden) */}
                                <div aria-hidden='true' className='hidden'>
                                    <input
                                        type='text'
                                        name='b_e23132eef5794f36db505272a_0679453965'
                                        tabIndex={-1}
                                        defaultValue=''
                                    />
                                </div>

                                {/* Submit Button */}
                                <div className='flex flex-wrap items-center justify-evenly pt-4'>
                                    <button
                                        type='submit'
                                        className='ibtn ibtn-secondary-alt py-3 px-6 lg:text-3xl sm:text-2xl text-xl'
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CTASection;
