import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/v2/logo/logo.svg';
import LogoIcon from '../../assets/v2/logo/logo-icon.svg';
import LogoWhite from '../../assets/v2/logo/logo-reverse.svg';
import IconQuestion from '../../assets/v2/icons/icon-question.svg';
import IconContact from '../../assets/v2/icons/icon-message.svg';
import IconFB from '../../assets/v2/icons/social-icon-fb.svg';
import IconLinkedin from '../../assets/v2/icons/social-icon-linkedin.svg';
import IconIG from '../../assets/v2/icons/social-icon-ig.svg';
import IconX from '../../assets/v2/icons/social-icon-x.svg';
import IconMedium from '../../assets/v2/icons/social-icon-medium.svg';

const AppFooter: React.FC = () => {
    return (
        <footer id='app-footer'>
            {/* Desktop Footer */}
            <div className='hidden md:flex md:flex-col bg-white border-t border-i-indigo'>
                <div className='container py-8 lg:px-8 sm:px-6 px-4 mx-auto flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0 text-i-indigo'>
                    <div className='flex flex-col items-center'>
                        <img className='w-52 h-auto mb-4' src={Logo} alt='Neotrust Logo' />
                        <div className='flex space-x-4'>
                            <a href='https://www.facebook.com/people/MyNeotrust/61556487141298/' target='_blank' rel='noreferrer' className='hover:opacity-75'>
                                <img src={IconFB} alt='Facebook' className='w-auto h-6' />
                            </a>
                            <a href='https://www.linkedin.com/company/myneotrust/' target='_blank' rel='noreferrer' className='hover:opacity-75'>
                                <img src={IconLinkedin} alt='LinkedIn' className='w-auto h-6' />
                            </a>
                            <a href='https://www.instagram.com/myneotrust/' target='_blank' rel='noreferrer' className='hover:opacity-75'>
                                <img src={IconIG} alt='Instagram' className='w-auto h-6' />
                            </a>
                            <a href='https://twitter.com/MyNeotrust/' target='_blank' rel='noreferrer' className='hover:opacity-75'>
                                <img src={IconX} alt='X (Twitter)' className='w-auto h-6' />
                            </a>
                            <a href='https://medium.com/@MyNeotrust/' target='_blank' rel='noreferrer' className='hover:opacity-75'>
                                <img src={IconMedium} alt='Medium' className='w-auto h-6' />
                            </a>
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row space-y-6 md:space-y-0 space-x-0 md:space-x-12 text-center md:text-left'>
                        <div className='flex flex-col items-center md:items-start'>
                            <span>
                                <img src={IconQuestion} alt='' className='w-auto h-6' />
                            </span>
                            <div className='font-semibold py-2'>Have Questions?</div>
                            <Link to='/faqs' className='text-sm hover:underline hover:opacity-75'>FAQ</Link>
                            <Link to='/contact' className='text-sm hover:underline hover:opacity-75'>Help</Link>
                        </div>
                        <div className='flex flex-col items-center md:items-start'>
                            <span>
                                <img src={LogoIcon} alt='' className='w-auto h-6' />
                            </span>
                            <div className='font-semibold py-2'>Company Info</div>
                            <Link to='/' className='text-sm hover:underline hover:opacity-75'>About</Link>
                            <Link to='/' className='text-sm hover:underline hover:opacity-75'>Press</Link>
                        </div>
                        <div className='flex flex-col items-center md:items-start'>
                            <span>
                                <img src={IconContact} alt='' className='w-auto h-6' />
                            </span>
                            <div className='font-semibold py-2'>Contact our Team</div>
                            <a href='mailto:hello@myneotrust.com' className='text-sm hover:underline hover:opacity-75'>hello@myneotrust.com</a>
                            <Link to='/contact' className='text-sm hover:underline hover:opacity-75'>Contact Us</Link>
                        </div>
                    </div>
                </div>
                <BaseFooter classes='container mx-auto px-8' />

            </div>

            {/* Mobile Footer */}
            <div className="bg-white md:hidden flex flex-col items-center text-i-indigo">
                <div className="p-4">
                    <img className="w-full h-auto" src={Logo} alt="Neotrust Logo" />
                </div>

                <div className="flex flex-wrap justify-between text-center py-6 gap-y-4 text-xs">

                    <div className="w-1/2 flex items-start justify-center">
                        <img src={IconQuestion} alt="Have Questions Icon" className="w-auto h-6 mr-2" />
                        <div className="text-left min-w-[130px]">
                            <div className="font-semibold pb-1">Have Questions?</div>
                            <Link to="/faqs" className="hover:underline hover:opacity-75">FAQ</Link><br />
                            <Link to="/contact" className="hover:underline hover:opacity-75">Help</Link>
                        </div>
                    </div>

                    <div className="w-1/2 flex items-start justify-center">
                        <img src={LogoIcon} alt="Company Info Icon" className="w-auto h-6 mr-2" />
                        <div className="text-left">
                            <div className="font-semibold pb-1">Company Info</div>
                            <Link to="/" className="hover:underline hover:opacity-75">About</Link><br />
                            <Link to="/" className="hover:underline hover:opacity-75">Press</Link>
                        </div>
                    </div>

                    <div className="w-1/2 flex items-start justify-center">
                        <img src={IconContact} alt="Contact our Team Icon" className="w-6 h-6 mr-2" />
                        <div className="text-left min-w-[130px]">
                            <div className="font-semibold pb-1">Contact our Team</div>
                            <a href="mailto:hello@myneotrust.com" className="hover:underline hover:opacity-75">hello@myneotrust.com</a><br />
                            <Link to="/contact" className="hover:underline hover:opacity-75">Contact Us</Link>
                        </div>
                    </div>

                    <div className="w-1/2 flex flex-col items-center">
                        <div className="flex space-x-2 justify-center pt-6">
                            <a href="https://www.facebook.com/people/MyNeotrust/61556487141298/" target="_blank" rel="noreferrer" className="hover:opacity-75">
                                <img src={IconFB} alt="Facebook" className="w-auto h-6" />
                            </a>
                            <a href="https://www.linkedin.com/company/myneotrust/" target="_blank" rel="noreferrer" className="hover:opacity-75">
                                <img src={IconLinkedin} alt="LinkedIn" className="w-auto h-6" />
                            </a>
                            <a href="https://www.instagram.com/myneotrust/" target="_blank" rel="noreferrer" className="hover:opacity-75">
                                <img src={IconIG} alt="Instagram" className="w-auto h-6" />
                            </a>
                            <a href="https://twitter.com/MyNeotrust/" target="_blank" rel="noreferrer" className="hover:opacity-75">
                                <img src={IconX} alt="X (Twitter)" className="w-auto h-6" />
                            </a>
                            <a href="https://medium.com/@MyNeotrust/" target="_blank" rel="noreferrer" className="hover:opacity-75">
                                <img src={IconMedium} alt="Medium" className="w-auto h-6" />
                            </a>
                        </div>
                    </div>
                </div>

                <BaseFooter fixedRows={true} />
            </div>

        </footer>
    );
};

interface BaseFooterProps {
	classes?: string;
    logo?: boolean;
    fixedRows?: boolean;
}
export const BaseFooter: React.FC<BaseFooterProps> = ({ classes = '', logo = false, fixedRows=false }) => {
    return (<>
        <div className='bg-i-indigo w-full flex items-center justify-around'>
            {logo && <img className='w-auto h-8 m-2' src={LogoWhite} alt='Neotrust Logo' />}
            <div className={`text-center text-xs text-white px-3 py-3 ${classes}`}>
                <p>
                    <span>Neotrust - 701 Ellicott Street, Buffalo, NY, 14203</span>
                    {fixedRows && <><br /></>}
                    <span> · © 2024 Consentize, Inc. · All rights reserved.</span>
                    {fixedRows && <><br /></>}
                    <span> · </span><Link to='/' className='hover:underline hover:opacity-75'>Sitemap</Link>
                    <span> · </span><Link to='/privacy-policy' className='hover:underline hover:opacity-75'>Privacy Policy</Link>
                    <span> · </span><Link to='/terms-and-conditions' className='hover:underline hover:opacity-75'>Terms of Service</Link>
                </p>
            </div>
        </div>

    </>);
}


export default AppFooter;
