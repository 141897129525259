import React from 'react';
import HeroSection from './HeroSection';
import IndustriesSection from './IndustriesSection';
import CTASection from './CTASection';
import DigitalTrustSection from './DigitalTrustSection';

const HomePage: React.FC = () => {
    return (
        <div id='homePageContainer' className='w-full flex flex-col flex-grow overflow-y-auto bg-white'>
            <HeroSection />
            <IndustriesSection />
            <CTASection />
            <DigitalTrustSection />
        </div>
    );
}

export default HomePage;
