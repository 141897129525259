import React from 'react';
import IconClock from '../../assets/v2/icons/icon-clock.svg';
import IconIdentity from '../../assets/v2/icons/icon-identity.svg';
import IconCertification from '../../assets/v2/icons/icon-certification.svg';
import IconBlockchain from '../../assets/v2/icons/icon-blockchain.svg';
import IconDataIntegrity from '../../assets/v2/icons/icon-data-integrity.svg';
import IconVerify from '../../assets/v2/icons/icon-verify.svg';

const TrustFeaturesSection: React.FC = () => {
    const features = [
        {
            icon: IconClock,
            title: 'Immutable Timestamping',
            description: 'Neotrust uses blockchain to timestamp digital assets, ensuring that the exact time and date of creation or modification is recorded in a tamper-proof manner. Once a timestamp is added to the blockchain, it cannot be altered, providing a permanent, audit-able record that ensures data integrity.'
        },
        {
            icon: IconIdentity,
            title: 'Verified Identity',
            description: 'Neotrust links your verified digital identity to the file, permanently associating the work with you as the creator or owner. This identity verification ensures that anyone who accesses the file knows who created or owns it, eliminating uncertainty around authorship or ownership.'
        },
        {
            icon: IconCertification,
            title: 'Ownership Certification',
            description: 'Neotrust certifies ownership of the digital asset by recording it on the blockchain. This creates a tamper-proof record that ties the asset to its rightful owner. This immutable certification ensures that your ownership cannot be challenged or altered, providing long-term security for your intellectual property.'
        },
        {
            icon: IconBlockchain,
            title: 'Blockchain Immutability',
            description: 'By storing the proof of ownership, timestamp, and identity verification on the blockchain, Neotrust ensures that the information is immutable and transparent. Once the data is on the blockchain, it cannot be changed, providing a permanent and publicly verifiable record of the file’s origin, ownership, and history.'
        },
        {
            icon: IconDataIntegrity,
            title: 'Data Integrity Protection',
            description: 'Neotrust’s technology ensures that digital files are protected from unauthorized modifications. Once registered, any attempt to alter the file would be detected, as the blockchain-based record ensures the original version of the file is securely stored. This helps prevent tampering or forgery of digital assets.'
        },
        {
            icon: IconVerify,
            title: 'File Verification',
            description: 'Anyone who receives or accesses the file can verify its authenticity, ownership, and creation date through Neotrust. The platform provides an easy way to check the digital trust credentials of the asset, making it transparent and secure for external parties to trust and use.'
        }
    ];

    return (
        <section className="relative flex bg-white">
            <div className="container mx-auto text-center py-12 lg:px-12 md:px-8 px-4">
                <h2 className="text-4xl font-archivo text-i-indigo">{'How We Add Trust'}</h2>
                <div className="text-2xl text-i-indigo-dark py-6">{'Neotrust provides tamper-proof timestamping of digital files and immutable records of identity and ownership, ensuring that creators and their works are permanently linked.'}</div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
                    {features.map((feature, index) => (
                        <div key={index} className="flex flex-col items-center text-center">
                            <img src={feature.icon} alt={feature.title} className="w-auto h-16 mb-4" />
                            <h3 className="text-3xl font-semibold text-i-indigo mb-4">{feature.title}</h3>
                            <div className="text-i-indigo-dark">{feature.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TrustFeaturesSection;
