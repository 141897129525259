import { UserAddress } from "./types";

// UserInformation.ts
interface FeatureDetails {
    _id: string;
    FeatureName: string;
    FeatureDescription: string;
    __v: number;
}

interface Feature {
    _id: string;
    planId: string;
    featureId: string;
    isAvailable: boolean;
    isUnlimited: boolean;
    limit: number;
    limitDurationInDays: number;
    featureDetails: FeatureDetails[];
}

interface Plan {
    _id: string;
    PlanName: string;
    PlanDescription: string;
    PlanPrice: number;
    PlanDurationInDays: number;
    __v: number;
    features: Feature[];
}

export interface User {
    _id: string;
    userEmail: string;
    __v: number;
    isEmailVerified: boolean;
    isNeoTrustKeyGenerated: boolean;
    onboardedDate: string;
    signedUpProvider: string;
    userName: string;
    verificationStatus: string;
    customerId: string;
    neoTrustKey: string;
    publicKey: string;
    blockCertTxHash: string;
    planId: string;
    plan: Plan;
}

export interface UserInfo {
    _id: string;
    userEmail: string;
    __v: number;
    isEmailVerified: boolean;
    isNeoTrustKeyGenerated: boolean;
    onboardedDate: string;
    signedUpProvider: string;
    userName: string;
    verificationStatus: string;
    customerId: string;
    neoTrustKey: string;
    publicKey: string;
    bChainAddress: string;
    blockCertTxHash: string;
    plan_name: string;
    address: UserAddress[];
}

export interface UserInfoAPIResponse {
    status: string;
    user: UserInfo[];
}

export const checkValidUserPlan = (data: UserInfoAPIResponse) => {

    if (data.user.length !== 0) {
        const user = data.user[0];
        if (user._id && user.isNeoTrustKeyGenerated) {
            if (user.plan_name && user.plan_name !== null) {
                const userplan: UserPlan = user.plan_name as UserPlan;
                if (userplan === UserPlan.Expired) {
                    return { userStatus: UserStatus.EXPIRED }
                } else {
                    // let isPremium: boolean = false;
                    // if (userplan === UserPlan.Creator_Plus || userplan === UserPlan.Enterprise) {
                    //     isPremium = true;
                    // }
                    // return { userStatus: UserStatus.COMPLETE, isPremium: isPremium }
                    return { userStatus: UserStatus.COMPLETE }
                }
            } else {
                return { userStatus: UserStatus.KEY_READY }
            }
        } else {
            return { userStatus: UserStatus.NO_USER }
        }
    }
    return { userStatus: UserStatus.NO_USER,  }
}

export enum UserStatus {
    NO_USER = "NO_USER",
    KEY_READY = "KEY_READY",
    COMPLETE = "COMPLETE",
    EXPIRED = "EXPIRED",
}

export enum UserPlan {
    Expired = "Expired",
    Creator = "Creator",
    Creator_Plus = "Creator_Plus",
    Essential_Trust = "Essential_Trust",
    Advanced_Trust = "Advanced_Trust",
    Enterprise = "Enterprise"
}