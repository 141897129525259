import React from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const plans = [
    {
        id: 'tier-essential',
        title: 'Essential Trust',
        description: 'For individuals & small businesses looking to prove ownership of their content.',
        price: '$10.00',
        features: [
            '50 stamps/month',
            'Basic analytics',
            'Perfect for individual users',
            'No File Mapping'
        ],
        buttonLabel: 'Sign Up'
    },
    {
        id: 'tier-advanced',
        title: 'Advanced Trust',
        description: 'For growing & mid-sized businesses with enhanced capabilities.',
        price: '$100.00',
        features: [
            '500 stamps/month',
            'Advanced analytics',
            'For professionals and small businesses',
            'File Mapping'
        ],
        buttonLabel: 'Sign Up'
    },
    {
        id: 'tier-global',
        title: 'Global Trust',
        description: 'Dedicated support and infrastructure for your organization/enterprise.',
        price: 'TBD',
        features: [
            'Unlimited stamps/month',
            'Advanced analytics',
            'For corporations and large-scale needs',
            'File Mapping'
        ],
        buttonLabel: 'Contact Us'
    }
];

const PricingPlansSection: React.FC = () => {
    const navigate = useNavigate();
    const { loginWithRedirect } = useAuth0();

    return (
        <section className='bg-white'>
            <div className='container mx-auto p-8 text-i-indigo'>
                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
                    {plans.map(({ id, title, description, price, features, buttonLabel }) => (
                        <div key={id}
                            className={`border border-i-indigo rounded-xl shadow-lg text-center hover:shadow-2xl transition-shadow duration-300 overflow-hidden flex flex-col justify-between
                                ${id === 'tier-global' ? 'bg-i-indigo text-white' : ''}
                            `}
                        >
                            <h3 className='text-3xl font-archivo text-i-mintleaf bg-i-indigo py-6 px-4 whitespace-nowrap'>{title}</h3>
                            <div className='p-4'>{description}</div>
                            <div className='flex justify-center'>
                                <div className='min-w-[180px] p-2 inline-flex items-baseline justify-center bg-i-indigo text-xl rounded-xl font-archivo text-i-mintleaf'>{price}<span className='text-base'>{'\\month'}</span></div>
                            </div>
                            <ul className='p-4 space-y-2'>
                                {features.map((feature, i) => (
                                    <li key={i} className='flex justify-center items-center'>
                                        <span className=''>
                                            {id === 'tier-essential' && i === features.length - 1 ? (
                                                <XMarkIcon className='h-4 w-4 text-red-500 inline-flex mr-2' aria-hidden='true' />
                                            ) : (
                                                <CheckIcon className={`h-4 w-4 inline-flex mr-2 ${id === 'tier-global' ? 'text-white' : 'text-i-indigo'}`} aria-hidden='true' />
                                            )}
                                            {feature}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                            <div className='pb-4'>
                                <button
                                    className='min-w-[180px] ibtn ibtn-secondary py-3 px-6'
                                    onClick={() => {
                                        if (id === 'tier-global') {
                                            navigate('/contact');
                                        } else {
                                            loginWithRedirect();
                                        }
                                    }}
                                >
                                    {buttonLabel}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PricingPlansSection;
