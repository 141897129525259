interface EnvConfigType {
    SITE_URL: string;
    BLOG_URL: string;
    API_DOMAIN: string;
    AUTH0_DOMAIN: string;
    AUTH0_CLIENTID: string;
    AUTH0_AUDIENCE: string;
    AUTH0_CALLBACK_URL: string;
    STRIPE_CREATOR_ID: string;
    STRIPE_CREATOR_PLUS_ID: string;
    STRIPE_PRICEID_ESSENTIAL_TRUST: string;
    STRIPE_PRICEID_ADVANCED_TRUST: string;
    FACEBOOK_PIXEL_ID: string;
    LINKEDIN_PARTNER_ID: string;
    GOOGLE_ANALYTICS_ID: string;
}

export const EnvConfig: EnvConfigType = {
    // SITE_ENV: process.env.REACT_APP_SITE_ENV,
    SITE_URL: process.env.REACT_APP_SITE_URL || '',
    BLOG_URL: process.env.REACT_APP_BLOG_URL || '',
    API_DOMAIN: process.env.REACT_APP_API_DOMAIN || '',
    AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || '',
    AUTH0_CLIENTID: process.env.REACT_APP_AUTH0_CLIENTID || '',
    AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || '',
    AUTH0_CALLBACK_URL: process.env.REACT_APP_AUTH0_CALLBACK_URL || '',
    STRIPE_CREATOR_ID: process.env.REACT_APP_STRIPE_CREATOR_ID || '',
    STRIPE_CREATOR_PLUS_ID: process.env.REACT_APP_STRIPE_CREATOR_PLUS_ID || '',
    STRIPE_PRICEID_ESSENTIAL_TRUST: process.env.REACT_APP_STRIPE_PRICEID_ESSENTIAL_TRUST || '',
    STRIPE_PRICEID_ADVANCED_TRUST: process.env.REACT_APP_STRIPE_PRICEID_ADVANCED_TRUST || '',
    
    FACEBOOK_PIXEL_ID: process.env.REACT_APP_FACEBOOK_PIXEL_ID || '',
    LINKEDIN_PARTNER_ID: process.env.REACT_APP_LINKEDIN_PARTNER_ID || '',
    GOOGLE_ANALYTICS_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '',
    
};

export const _IS_PROD_ENV: boolean = process.env.REACT_APP_SITE_ENV === 'production';
