import React, { useEffect } from 'react';
import { _IS_PROD_ENV } from '../utils/envConfig'; // Assuming your config file is in this path

const TrackingScripts: React.FC = () => {
    useEffect(() => {
        if (!_IS_PROD_ENV) {
            console.log('Skipping tracking scripts: Not in production mode.');
            return;
        }

        // Facebook Pixel
        const facebookPixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
        if (facebookPixelId) {
            try {
                (function (f: any, b: any, e: any, v: string, n?: any, t?: any, s?: any) {
                    if (f.fbq) return;
                    n = f.fbq = function (...args: any[]) {
                        n.callMethod ? n.callMethod.apply(n, args) : n.queue.push(args);
                    };
                    if (!f._fbq) f._fbq = n;
                    n.push = n;
                    n.loaded = true;
                    n.version = '2.0';
                    n.queue = [];
                    t = b.createElement(e);
                    t.async = true;
                    t.src = v;
                    s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t, s);
                })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
                window.fbq?.('init', facebookPixelId);
                window.fbq?.('track', 'PageView');
                console.log('Facebook Pixel initialized successfully.');
            } catch (error) {
                console.error('Failed to initialize Facebook Pixel:', error);
            }
        } else {
            console.warn('Facebook Pixel ID is not configured.');
        }

        // LinkedIn Insight Tag
        const linkedinPartnerId = process.env.REACT_APP_LINKEDIN_PARTNER_ID;
        if (linkedinPartnerId) {
            try {
                window._linkedin_partner_id = linkedinPartnerId;
                window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                window._linkedin_data_partner_ids.push(linkedinPartnerId);

                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                script.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                document.head.appendChild(script);

                console.log('LinkedIn Insight Tag initialized successfully.');
            } catch (error) {
                console.error('Failed to initialize LinkedIn Insight Tag:', error);
            }
        } else {
            console.warn('LinkedIn Partner ID is not configured.');
        }

        // Google Analytics
        const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
        if (googleAnalyticsId) {
            try {
                const script = document.createElement('script');
                script.async = true;
                script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
                document.head.appendChild(script);

                const scriptConfig = document.createElement('script');
                scriptConfig.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${googleAnalyticsId}');
                `;
                document.head.appendChild(scriptConfig);

                console.log('Google Analytics initialized successfully.');
            } catch (error) {
                console.error('Failed to initialize Google Analytics:', error);
            }
        } else {
            console.warn('Google Analytics ID is not configured.');
        }
    }, []);

    return null; // This component doesn't render anything
};

export default TrackingScripts;
