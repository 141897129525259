import React from 'react';
import HeroSection from './HeroSection';
import FAQsListSection from './FAQsListSection';
import FAQsFeaturesSection from './FAQsFeaturesSection';


const FAQsPage: React.FC = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <HeroSection />
            <FAQsFeaturesSection />
            <FAQsListSection />
        </div>
    );
};

export default FAQsPage;
