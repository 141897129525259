import React from "react";
import Image from "../../assets/v2/images/img-industries-home.svg";

const ContentSection = () => {
    return (
        <section className="bg-white py-12">
            <div className="container mx-auto px-6 lg:px-16 grid grid-cols-1 md:grid-cols-[3fr_2fr] xl:grid-cols-2 gap-8 items-center">
                {/* Left Column - Text Content */}
                <div>
                    <h2 className="text-4xl font-archivo text-i-indigo mb-4">Secure your digital future</h2>
                    <p className="text-lg text-i-indigo mb-6">
                        Neotrust ensures that your digital files and assets are secure, verifiable, and trusted. 
                        Neotrust solves the big problems you’ve always had in the digital world.
                    </p>
                    <div className="space-y-6">
                        <div>
                            <h3 className="text-xl font-semibold text-i-indigo mb-2">Building Consumer Trust</h3>
                            <p className="text-gray-600">
                                With Neotrust, businesses can accurately track and trace products from source to sale, 
                                providing consumers with transparency about the origin and journey of their food and beverages. 
                                This builds confidence in the brand, as customers know they are receiving high-quality, 
                                authentically sourced products.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold text-i-indigo mb-2">Improving Operational Efficiency and Compliance</h3>
                            <p className="text-gray-600">
                                Neotrust enables companies to dramatically reduce recall identification time from 14 days 
                                to the same day. This rapid response capability is critical for maintaining compliance 
                                and ensuring consumer safety, particularly in industries like food and beverage where 
                                timely actions are essential.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold text-i-indigo mb-2">Reducing Costs</h3>
                            <p className="text-gray-600">
                                Implementing Neotrust can lead to significant cost savings, with average reductions 
                                of over $150K annually per department. This efficiency allows businesses to allocate 
                                resources more effectively while enhancing their bottom line.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold text-i-indigo mb-2">Real-World Impact in the Food & Beverage Supply Chain</h3>
                            <p className="text-gray-600">
                                A notable example of Neotrust in action is its partnership with Rich Products to secure 
                                their supply chain documentation. With full deployment, this partnership is projected 
                                to generate up to $2 million in annual recurring revenue. Currently, Neotrust is live 
                                in products across over 300 stores, including brands like Seidel’s Relish and 
                                Unapologetic Coffee, showcasing the tangible benefits of blockchain technology in 
                                securing and streamlining supply chain processes.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Right Column - Image */}
                <div className="relative">
                    <img
                        src={Image}
                        alt="Neotrust Securing Your Supply Chain"
                        className="rounded-lg shadow-md w-full h-full max-w-full"
                    />
                    <div className="bg-i-indigo font-semibold text-xl text-white text-center py-4 rounded-b-lg mt-[-6px]">
                        Neotrust is securing your supply chain.
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContentSection;
