import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import API from '../../apiClient/NeoTrustAPI';
import { useAuth0 } from '@auth0/auth0-react';
import { TransactionStatusKeys, TransactionStatusValues, TransactionTypeKeys, TransactionTypeValues, UserTransactionsAPIResponse } from '../../utils/types';
import { STATUS, formatFileSize, formatISODate, formatTimestamp } from '../../utils/common';
import LogoIcon from '../../assets/v2/logo/icon-reverse.svg';

interface TransactionTableDataType {
    key: React.Key;
    _id: string;
    featureName: string;
    transactionStatus: string;
    transactionDateTime: string;
    onchainTxResult: any;
    fileMetadata: any;
    // age: number;
    // address: string;
}
interface FileMetadataTableDataType {
    key: React.Key;
    fileName: string;
    fileType: string;
    fileSize: string;
    fileLastModified: string;
    fileHash: string;
}

const TransactionColumns: ColumnsType<TransactionTableDataType> = [
    {
        title: 'Transaction ID',
        dataIndex: '_id',
        key: '_id',
        ellipsis: true,
    },
    {
        title: 'Transaction Type',
        dataIndex: 'featureName',
        key: 'featureName',
        ellipsis: true,
        render: (value, record, i) => <>{TransactionTypeValues[value as keyof typeof TransactionTypeValues]}</>,
        sorter: true,
        onFilter: (value: any, record: any) => true,
        filters: Object.keys(TransactionTypeValues).map(key => ({
            text: TransactionTypeValues[key as keyof typeof TransactionTypeValues],
            value: key
        }))
    },
    {
        title: 'Status',
        dataIndex: 'transactionStatus',
        key: 'transactionStatus',
        ellipsis: true,
        render: (value, record, i) => <>{TransactionStatusValues[value as keyof typeof TransactionStatusValues]}</>,
        sorter: true,
        onFilter: (value: any, record: any) => true,
        filters: Object.keys(TransactionStatusValues).map(key => ({
            text: TransactionStatusValues[key as keyof typeof TransactionStatusValues],
            value: key
        }))
    },
    {
        title: 'Transaction Time',
        dataIndex: 'transactionDateTime',
        key: 'transactionDateTime',
        ellipsis: true,
        render: (value, record, i) => <>{formatISODate(value)}</>,
        onFilter: (value: any, record: any) => true,
        sorter: true,
    },
    {
        title: 'Onchain Tx Block No.',
        dataIndex: 'onchainTxResult',
        key: 'onchainTxResult',
        ellipsis: true,
        render: (value, record, i) => <>{value?.height ?? '-'}</>,
    },
    {
        title: 'Onchain Tx Timestamp',
        dataIndex: 'onchainTxResult',
        key: 'onchainTxResult',
        ellipsis: true,
        render: (value, record, i) => <>{value?.timestamp ?? '-'}</>,
    },
    {
        title: 'Actions',
        dataIndex: '_id',
        key: 'actions',
        ellipsis: true,
        render: (value, record, i) => {
            if (value && (record.transactionStatus === TransactionStatusKeys.recorded || record.transactionStatus === TransactionStatusKeys.downloaded)) {
                const url = `${window.location.origin}/download/${value}`
                return <>
                    <a
                        href={url}
                        target='_blank'
                        className="py-2 px-4 ibtn ibtn-primary text-sm"
                    >
                        Download
                    </a>
                </>
            } else {
                return <>{'-'}</>
            }
        },
    },
];

const FileMetadataColumns: ColumnsType<FileMetadataTableDataType> = [
    {
        title: 'File Name',
        dataIndex: 'fileName',
        key: 'fileName',
        ellipsis: true,
        width: '25%',
    },
    {
        title: 'File Type',
        dataIndex: 'fileType',
        key: 'fileType',
        ellipsis: true,
        width: '15%',
    },
    {
        title: 'File Size',
        dataIndex: 'fileSize',
        key: 'fileSize',
        ellipsis: true,
        width: '15%',
        render: (value, record, i) => <>{formatFileSize(value)}</>,
    },
    {
        title: 'File Last Modified',
        dataIndex: 'fileLastModified',
        key: 'fileLastModified',
        ellipsis: true,
        width: '15%',
        render: (value, record, i) => <>{formatTimestamp(value)}</>,
    },
    {
        title: 'File Hash',
        dataIndex: 'fileHash',
        key: 'fileHash',
        width: '30%',
        ellipsis: true,
    },
];

interface SortState { sortColumn: string; sortOrder: 'desc' | 'asc'; }
const DEFAULT_SORT_STATE: SortState = { sortColumn: 'transactionDateTime', sortOrder: 'desc' }

interface FilterState { featureName: string[]; transactionStatus: string[]; }
const DEFAULT_FILTER_STATE: FilterState = { featureName: [], transactionStatus: [] }

interface PaginationState { page: number; limit: number; }
const DEFAULT_PAGINATION_STATE: PaginationState = { page: 1, limit: 10 }


const TransactionsTable: React.FC = () => {

    const api = API.getInstance(useAuth0()).getAxiosInstance(); // Pass auth0Context to API instance
    const [tableData, setTableData] = useState<TransactionTableDataType[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [sortState, setSortState] = useState<SortState>(DEFAULT_SORT_STATE);
    const [filterState, setFilterState] = useState<FilterState>(DEFAULT_FILTER_STATE);
    const [paginationState, setPaginationState] = useState<PaginationState>(DEFAULT_PAGINATION_STATE);
    const [loader, setLoader] = useState<STATUS>(STATUS.NONE);

    const getUserTransactions = async () => {
        try {
            let params: any = {
                page: paginationState.page,
                limit: paginationState.limit,
                sortColumn: sortState.sortColumn,
                sortOrder: sortState.sortOrder,
            };
            filterState.featureName.length && (params = { ...params, featureName: filterState.featureName.join(',') });
            filterState.transactionStatus.length && (params = { ...params, transactionStatus: filterState.transactionStatus.join(',') });
            const response = await api.get('/user/transactions', {
                params: params
            });
            const data: UserTransactionsAPIResponse = response.data;
            // console.log('User transactions:', data);
            let tableData: TransactionTableDataType[] = [];
            data.data.transactions.forEach((transaction) => {
                // if (transaction.featureName === 'timestamp' || transaction.featureName === 'timestamp_and_store' || transaction.featureName === 'verify') {
                tableData.push({
                    key: transaction._id,
                    _id: transaction._id,
                    transactionStatus: transaction.transactionStatus,
                    transactionDateTime: transaction.transactionDateTime,
                    featureName: transaction.featureName,
                    onchainTxResult: transaction.onchainTxResult,
                    fileMetadata: transaction.fileMetadata,
                });
                // }
            });
            setTotalCount(data.data.totalCount);
            setTableData(tableData);
            setLoader(STATUS.SUCCESS);
        } catch (e: any) {
            console.error('Error fetching user transactions:', e);
            // showToastUtil({ status: TOAST_TYPE.ERROR, message: e?.message || 'Error fetching user info.' });
            setLoader(STATUS.ERROR);
        }
    };

    useEffect(() => {
        getUserTransactions();
    }, []);

    useEffect(() => {
        if (loader === STATUS.PROCESSING) {
            getUserTransactions();
        }
    }, [loader]);

    const expandedRowRender = (record: any) => {
        const fileMetadataData = record.fileMetadata;

        return (
            <Table
                className='file-metadata-table'
                columns={FileMetadataColumns}
                dataSource={fileMetadataData}
                pagination={false}
                size="small"
            />
        );
    };


    return (<>
        {/* <div className="page-container justify-center items-center relative bg-ntbg-purples"> */}
        <div className='bg-i-indigo rounded-t-xl text-white text-xl px-4 py-3 flex flex-row items-center'>
            <img className="w-auto h-8" src={LogoIcon} alt="" />
            <span className='font-archivo pl-3'>{'Your Recent Stamps'}</span>
        </div>
        <div className='relative overflow-x-scroll flex flex-col flex-auto'>
            <Table
                className='min-w-[1000px]'
                columns={TransactionColumns}
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: [],
                    rowExpandable: record => record.featureName === TransactionTypeKeys.timestamp || record.featureName === TransactionTypeKeys.timestamp_and_store,
                }}
                dataSource={tableData}
                size="middle"
                // size="large"
                onChange={(pagination, filters, sorter, extra) => {
                    if (extra.action === 'paginate') {
                        // handled within pagination change methods
                    } else if (extra.action === 'sort') {
                        let sort: any = sorter;
                        if (sort.columnKey && sort.order) {
                            setSortState({ sortColumn: sort.columnKey, sortOrder: sort.order === 'descend' ? 'desc' : 'asc' });
                        } else {
                            setSortState(DEFAULT_SORT_STATE);
                        }
                        setLoader(STATUS.PROCESSING);
                    } else if (extra.action === 'filter') {
                        if (filters.featureName && filters.featureName.length) {
                            setFilterState({ ...filterState, featureName: filters.featureName as string[] });
                        } else if (filters.transactionStatus && filters.transactionStatus.length) {
                            setFilterState({ ...filterState, transactionStatus: filters.transactionStatus as string[] });
                        } else {
                            setFilterState(DEFAULT_FILTER_STATE);
                        }
                        setLoader(STATUS.PROCESSING);
                    }
                }}
                pagination={{
                    className: 'bg-i-indigo text-white !m-0 p-3 rounded-b-lg',
                    position: ['bottomCenter'],
                    total: totalCount,
                    current: paginationState.page,
                    pageSize: paginationState.limit,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => {
                        setPaginationState({ page: 1, limit: size });
                        setLoader(STATUS.PROCESSING);
                    },
                    onChange: (page, pageSize) => {
                        setPaginationState({ page: page, limit: pageSize });
                        setLoader(STATUS.PROCESSING);
                    },
                    showTotal: (total, [lo, hi]) => <>
                        <div style={{ float: 'right' }}>{`Displaying ${lo}-${hi} out of ${total} records`}</div>
                    </>
                }}
            />
            {(loader === STATUS.PROCESSING || loader === STATUS.NONE) && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-10">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-i-indigo"></div>
                </div>
            )}
        </div>
        {/* </div> */}
    </>);
}

export default TransactionsTable;